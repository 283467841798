import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthCommon from "../AuthCommon";
import * as API from "../../utils/api.service";
import { USER } from "../../app.config";
import { AppContext } from "../../appContext";

const Login = () => {
  const [fields, setFields] = useState({ email: "", password: "" });
  const navigate = useNavigate();
  const { setLoader } = useContext(AppContext);

  const handleSubmit = async (e) => {
    try {
      setLoader(true);
      e.preventDefault();
      const { data, message, code } = await API.login({
        ...fields,
        device_token: "test",
        device_type: "W",
        timezone: "Asia/Kolkata",
      });
      if (code == 1) {
        localStorage.setItem(USER, JSON.stringify(data));
        navigate("/");
      } else alert(message);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      alert(err.message);
    }
  };

  return (
    <div className="main">
      <section className="login-main">
        <AuthCommon>
          <Link to="/signup">Sign up</Link>
        </AuthCommon>
        <div className="section-right">
          <div className="login-form">
            <div className="login-title">
              <h3>Let’s Get Started</h3>
              <p>Let us help you save energy &amp; money</p>
            </div>

            <div className="login-tabs">
              <form onSubmit={handleSubmit} className="common_form">
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    onChange={(e) => setFields({ ...fields, email: e.target.value })}
                    value={fields.email}
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email..."
                    required
                  />
                </div>
                <div className="mb-3 form-group">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Enter Password
                  </label>
                  <input
                    type="password"
                    onChange={(e) => setFields({ ...fields, password: e.target.value })}
                    value={fields.password}
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="********"
                    required
                  />
                  {/* <a href="#" className="icon-hide-show">
                    <img src="assets/images/icon-eyes.svg" className="img-fluid" />
                  </a> */}
                </div>
                <button type="submit" className="btn-white mt-3 mb-3 d-block w-100 wow slideInUp">
                  Login
                </button>
                {/* <!-- <a href="index.html" className="btn-theme me-3 w-100 mt-3 mb-3 d-block">Login</a> --> */}
                <a href="#" className="forgot-password">
                  Forgot Password?
                </a>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
