import { Link } from "react-router-dom";

const AuthCommon = ({ children }) => {
  return (
    <div className="section-left">
      <Link to="/" className="btn btn-home">
        <i className="ti ti-wave-square"></i> Home
      </Link>
      <div className="login-details">
        {/* <!--  <img src="assets/images/login-logo.svg" className="img-fluid"> --> */}
        <img src="assets/images/resources/logo-main.png" className="img-fluid" />
        <h3>Your Personal AI Music Creator</h3>
        <p>Create AI music in seconds</p>
        {/* <!-- <a href="sign-up.html" className="btn btn-black mt-3">Sign Up</a> --> */}
        <Link className="btn-red  wow slideInUp mt-3 w-300px" to="/signup">
          Sign Up
        </Link>
        <div className="have-an-account">
          <p>Don't have an account? {children}</p>
        </div>
      </div>
    </div>
  );
};

export default AuthCommon;
