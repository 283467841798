import { Routes, Route } from "react-router-dom";
import Layout from "../layout";
import HomePage from "../pages/Home";
import MusicGeneratorPage from "../pages/MusicGenerator";
import MusicAssistantPage from "../pages/MusicAssistant";
import LoginPage from "../pages/Login";
import SignUpPage from "../pages/SignUp";
import AboutUsPage from "../pages/AboutUs";
import ContactUsPage from "../pages/ContactUs";
import FAQPage from "../pages/FAQs";
import PrivacyPage from "../pages/Privacy";
import TermsPage from "../pages/Terms";

const Router = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignUpPage />} />
      <Route element={<Layout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/music-generator" element={<MusicGeneratorPage />} />
        <Route path="/music-assistant" element={<MusicAssistantPage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/contact" element={<ContactUsPage />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/terms" element={<TermsPage />} />
      </Route>
    </Routes>
  );
};

export default Router;
