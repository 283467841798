const AboutUsPage = () => {
  return (
    <section className="block mt-5">
      <div className="container-fluid">
        <div className="row about-sec">
          <div className="col-xl-6 col-lg-12 col-md-12 col-12">
            <div className="about-us wow fadeInLeft" data-wow-delay="100ms" data-wow-duration="1000ms">
              <h2>ABOUT US</h2>
              <p>
                Rolling Out - Ai Music Lab is a groundbreaking music store that utilizes AI technology to generate
                unique and creative music for a variety of purposes. Our team of experienced musicians and programmers
                has developed an AI music generator that is capable of creating original compositions in a wide range of
                styles and genres. Whether you're looking for music for a film, a video game, or just for personal
                enjoyment, Rolling Out - Ai Music Lab has you covered. Click here to learn more about our innovative
                music generation technology.
              </p>
              <p>
                At Rolling Out - Ai Music Lab, we're passionate about music and technology. Our team is comprised of
                talented musicians and programmers who are dedicated to pushing the boundaries of what's possible with
                AI-generated music. We believe that AI technology has the potential to revolutionize the way music is
                created, and we're committed to staying at the forefront of this exciting field. Whether you're a
                musician, a filmmaker, a game developer, or just a music lover, we invite you to explore our website and
                discover the possibilities of AI-generated music.
              </p>

              {/* <!-- <a className="btn-outline-white  wow slideInUp" href="#" title="">Explore Now</a> --> */}
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 col-12">
            <div className="about-image wow fadeInRight" data-wow-delay="100ms" data-wow-duration="2000ms">
              <img src="assets/images/resources/about.png" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsPage;
