const AudioList = ({ fields, showAudioList }) => {
  return (
    <>
      <div className="ganerator-song-title mb-3">
        <div className="song-images">
          <img src="assets/images/resources/ganerated-music.jpg" className="img-fluid" />
        </div>
        <div className="song-details">
          {fields.genre ? <h3>{fields.genre}</h3> : ""}
          <div className="selected-genr-list">
            {fields.genre ? <span className="text-capitalize">{fields.genre}</span> : ""}
            {fields?.instruments.length > 0 &&
              fields?.instruments.map((instrument, i) => {
                return (
                  <span className="text-capitalize" key={i}>
                    {instrument}
                  </span>
                );
              })}
            {fields.bpm ? <span>BPM : {fields.bpm}</span> : ""}{" "}
            {fields.duration ? <span>Durations : {fields.duration}s</span> : ""}
          </div>
        </div>
      </div>
      <div className="ganerated-music-body">{showAudioList}</div>
    </>
  );
};

export default AudioList;
