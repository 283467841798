import { Link } from "react-router-dom";
import Menus from "../Menus";
import { useContext } from "react";
import { AppContext } from "../../appContext";
import logoBlack from "../../assets/images/logo-main.png"

const Navbar = () => {
  const {isOpen, handleToggleMenu } = useContext(AppContext);
  return (
    <>
      <header>
        <div className="container-fluid p-0">
          <div className="header-content">
            <div className="logo">
              <h2>
                <Link to="/" className="white-logo">
                  <img src={logoBlack} className="img-fluid logo-black" />
                  <img src={logoBlack} className="img-fluid logo-white" />
                </Link>
              </h2>
            </div>
            <nav>
              <Menus />
            </nav>
            <div onClick={handleToggleMenu} className={`menu-btn ${isOpen ? "active" : ""}`}>
              <span className="bar1"></span>
              <span className="bar2"></span>
              <span className="bar3"></span>
            </div>
          </div>
        </div>
      </header>
      <div className={`responsive-mobile-menu ${isOpen ? "active" : ""}`}>
        <Menus />
      </div>
    </>
  );
};

export default Navbar;
