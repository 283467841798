const Spinner = () => {
  return (
    <div className="preloader">
      <div className="loader-position">
        <div className="spinner">
          <div className="r1"></div>
          <div className="r2"></div>
          <div className="r3"></div>
          <div className="r4"></div>
          <div className="r5"></div>
        </div>
        <a href="#" className="loader-logo">
          <img src="assets/images/resources/logo-main.png" alt="logo" />
        </a>
      </div>
    </div>
  );
};

export default Spinner;
