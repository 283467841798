import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as API from "../../utils/api.service";
import { USER } from "../../app.config";
import { AppContext } from "../../appContext";

const ContactUs = () => {
  const [fields, setFields] = useState({ fName: "", lName: "", email: "", phone: "", message: "" });
  const { setLoader } = useContext(AppContext);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  };

  const handleSubmit = async (e) => {
    try {
      setLoader(true);
      e.preventDefault();
      const { fName, lName, email, phone, message } = fields;
    } catch (err) {
      setLoader(false);
      alert(err.message);
    }
  };
  return (
    <div className="main mt-5 mb-3">
      <section>
        {/* <div className="section-left contact-image">
      <img src="assets/images/resources/about.png" alt="" />
    </div> */}
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10 col-12">
              <div className="login-form w-100">
                <div className="login-title">
                  <h3>Contact Us</h3>
                  <p>Get in Touch:</p>
                </div>

                <div className="login-tabs">
                  <form onSubmit={handleSubmit} className="common_form">
                    <div className="mb-3">
                      <label htmlFor="exampleInputEmail1" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        onChange={handleChange}
                        name="fName"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter first name"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleInputEmail1" className="form-label">
                        Last Name
                      </label>
                      <input
                        type="text"
                        onChange={handleChange}
                        name="lName"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter last name"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="exampleInputEmail1" className="form-label">
                        Email address
                      </label>
                      <input
                        type="email"
                        onChange={handleChange}
                        name="email"
                        className="form-control"
                        aria-describedby="emailHelp"
                        placeholder="Enter email"
                        required
                      />
                    </div>
                    <div className="mb-3 form-group">
                      <label htmlFor="exampleInputPassword1" className="form-label">
                        Enter Phone
                      </label>
                      <input
                        type="tel"
                        onChange={handleChange}
                        name="phone"
                        className="form-control"
                        placeholder="Enter phone"
                        required
                      />
                      {/* <!-- <a href="#" className="icon-hide-show"><img src="assets/images/icon-eyes.svg" className="img-fluid"></a> --> */}
                    </div>
                    <div className="mb-3 form-group">
                      <label htmlFor="exampleInputPassword1" className="form-label">
                        Leave us a message...
                      </label>
                      <textarea
                        row="4"
                        type="text"
                        onChange={handleChange}
                        name="message"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Enter messsage"
                        required
                      />
                      {/* <!-- <a href="#" className="icon-hide-show"><img src="assets/images/icon-eyes.svg" className="img-fluid"></a> --> */}
                    </div>
                    <div className="text-center">
                      <button type="submit" className="btn-white mt-3 mb-3 w-220px wow slideInUp">
                        Submit
                      </button>
                    </div>
                    {/* <!-- <a href="index.html" className="btn-theme me-3 w-100 mt-3 mb-3 d-block">Login</a> -->
                       <!-- <a href="forgot-password.html" className="forgot-password">Forgot Password?</a> --> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
