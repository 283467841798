// MyContext.js
import React, { createContext, useState } from "react";

const AppContext = createContext();

function ContextProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false)

  const setLoader = (status) => {
    setIsLoading(status);
  };

  const handleToggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const handleCloseMenu = () => {
    if(isOpen)
    setIsOpen(false)
  }

  return <AppContext.Provider value={{ isLoading, setLoader, handleToggleMenu, handleCloseMenu, isOpen }}>{children}</AppContext.Provider>;
}

export { AppContext, ContextProvider };
