import { useMemo, useState } from "react";
import { TRACK_LIST } from "../../app.config";
import AudioPlayer from "../AudioPlayer";

const Tracks = ({handelShowNext, trackRef}) => {
    const [playingAudioId, setPlayingAudioId] = useState("")

    const showTrackList = useMemo(() => {
        return TRACK_LIST.map(track => {
            return <AudioPlayer key={track.id} track={track} playingAudioId={playingAudioId} handlePlayigAudioId = { (id) => setPlayingAudioId(id)} showOptions={false} showDjImage={true} />
        })
    },[TRACK_LIST, playingAudioId])

  return (
    <section ref={trackRef} className="block pick-your-tracks-main" id="pick-your-tracks-main">
      <div className="container-fluid">
        <div className="row justify-content-center pt-3">
          <div className="col-lg-8 col-md-10 col-12">
            <div className="title-sec mb-3 text-center wow fadeInUp">
              <h2>Pick Your Tracks & Plan Your Mix</h2>
              <p>
                Track selection is the heart and soul of DJing. Selecting the right track at the right time can elevate
                your set and captivate your audience. The first step in effective track selection is to know your music
                library inside out. That’s where we come in. Our AI will help you nail your structure, energy level,
                genre, and mood.
              </p>
            </div>

            <div className="ganerated-music-body pick-your-tracks-body mt-3">
                {showTrackList}
              {/* <div className="ganerated-music-list pick-your-tracks">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                    <div className="ms-player-content">
                      <div className="ms-player jp-state-playing" id="jp_container_2">
                        <div className="ms-thumb">
                          <img src="assets/images/resources/music-list.jpg" alt="" />
                        </div>

                        <div className="title-bar">
                          <div className="ms-top">
                            <h4 className="jp-title"></h4>
                            <div className="jp-time-holder">
                              <div className="jp-current-time" role="timer" aria-label="time">
                                &nbsp;
                              </div>
                              <div className="jp-duration" role="timer" aria-label="duration">
                                &nbsp;
                              </div>
                            </div>
                          </div>
                          <div className="new-play-added">
                            <div className="jp-controls text-center">
                              <button className="jp-play" tabIndex="0">
                                <i className="fas fa-play"></i>
                              </button>
                            </div>
                            <div className="jp-progress">
                              <div className="jp-seek-bar">
                                <div className="jp-play-bar"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="jp-volume-controls"></div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="ganerated-music-list pick-your-tracks">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                    <div className="ms-player-content">
                      <div className="ms-player jp-state-playing" id="jp_container_2">
                        <div className="ms-thumb">
                          <img src="assets/images/resources/music-list.jpg" alt="" />
                        </div>

                        <div className="title-bar">
                          <div className="ms-top">
                            <h4 className="jp-title"></h4>
                            <div className="jp-time-holder">
                              <div className="jp-current-time" role="timer" aria-label="time">
                                &nbsp;
                              </div>
                              <div className="jp-duration" role="timer" aria-label="duration">
                                &nbsp;
                              </div>
                            </div>
                          </div>
                          <div className="new-play-added">
                            <div className="jp-controls text-center">
                              <button className="jp-play" tabIndex="0">
                                <i className="fas fa-play"></i>
                              </button>
                            </div>
                            <div className="jp-progress">
                              <div className="jp-seek-bar">
                                <div className="jp-play-bar"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="jp-volume-controls"></div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="ganerated-music-list pick-your-tracks">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                  <label className="form-check-label" htmlFor="flexRadioDefault3">
                    <div className="ms-player-content">
                      <div className="ms-player jp-state-playing" id="jp_container_2">
                        <div className="ms-thumb">
                          <img src="assets/images/resources/music-list.jpg" alt="" />
                        </div>

                        <div className="title-bar">
                          <div className="ms-top">
                            <h4 className="jp-title"></h4>
                            <div className="jp-time-holder">
                              <div className="jp-current-time" role="timer" aria-label="time">
                                &nbsp;
                              </div>
                              <div className="jp-duration" role="timer" aria-label="duration">
                                &nbsp;
                              </div>
                            </div>
                          </div>
                          <div className="new-play-added">
                            <div className="jp-controls text-center">
                              <button className="jp-play" tabIndex="0">
                                <i className="fas fa-play"></i>
                              </button>
                            </div>
                            <div className="jp-progress">
                              <div className="jp-seek-bar">
                                <div className="jp-play-bar"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="jp-volume-controls"></div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="ganerated-music-list pick-your-tracks">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                  <label className="form-check-label" htmlFor="flexRadioDefault4">
                    <div className="ms-player-content">
                      <div className="ms-player jp-state-playing" id="jp_container_2">
                        <div className="ms-thumb">
                          <img src="assets/images/resources/music-list.jpg" alt="" />
                        </div>

                        <div className="title-bar">
                          <div className="ms-top">
                            <h4 className="jp-title"></h4>
                            <div className="jp-time-holder">
                              <div className="jp-current-time" role="timer" aria-label="time">
                                &nbsp;
                              </div>
                              <div className="jp-duration" role="timer" aria-label="duration">
                                &nbsp;
                              </div>
                            </div>
                          </div>
                          <div className="new-play-added">
                            <div className="jp-controls text-center">
                              <button className="jp-play" tabIndex="0">
                                <i className="fas fa-play"></i>
                              </button>
                            </div>
                            <div className="jp-progress">
                              <div className="jp-seek-bar">
                                <div className="jp-play-bar"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="jp-volume-controls"></div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="ganerated-music-list pick-your-tracks">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" />
                  <label className="form-check-label" htmlFor="flexRadioDefault5">
                    <div className="ms-player-content">
                      <div className="ms-player jp-state-playing" id="jp_container_2">
                        <div className="ms-thumb">
                          <img src="assets/images/resources/music-list.jpg" alt="" />
                        </div>

                        <div className="title-bar">
                          <div className="ms-top">
                            <h4 className="jp-title"></h4>
                            <div className="jp-time-holder">
                              <div className="jp-current-time" role="timer" aria-label="time">
                                &nbsp;
                              </div>
                              <div className="jp-duration" role="timer" aria-label="duration">
                                &nbsp;
                              </div>
                            </div>
                          </div>
                          <div className="new-play-added">
                            <div className="jp-controls text-center">
                              <button className="jp-play" tabIndex="0">
                                <i className="fas fa-play"></i>
                              </button>
                            </div>
                            <div className="jp-progress">
                              <div className="jp-seek-bar">
                                <div className="jp-play-bar"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="jp-volume-controls"></div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="ganerated-music-list pick-your-tracks">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault6" />
                  <label className="form-check-label" htmlFor="flexRadioDefault6">
                    <div className="ms-player-content">
                      <div className="ms-player jp-state-playing" id="jp_container_2">
                        <div className="ms-thumb">
                          <img src="assets/images/resources/music-list.jpg" alt="" />
                        </div>

                        <div className="title-bar">
                          <div className="ms-top">
                            <h4 className="jp-title"></h4>
                            <div className="jp-time-holder">
                              <div className="jp-current-time" role="timer" aria-label="time">
                                &nbsp;
                              </div>
                              <div className="jp-duration" role="timer" aria-label="duration">
                                &nbsp;
                              </div>
                            </div>
                          </div>
                          <div className="new-play-added">
                            <div className="jp-controls text-center">
                              <button className="jp-play" tabIndex="0">
                                <i className="fas fa-play"></i>
                              </button>
                            </div>
                            <div className="jp-progress">
                              <div className="jp-seek-bar">
                                <div className="jp-play-bar"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="jp-volume-controls"></div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="ganerated-music-list pick-your-tracks">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault7" />
                  <label className="form-check-label" htmlFor="flexRadioDefault7">
                    <div className="ms-player-content">
                      <div className="ms-player jp-state-playing" id="jp_container_2">
                        <div className="ms-thumb">
                          <img src="assets/images/resources/music-list.jpg" alt="" />
                        </div>

                        <div className="title-bar">
                          <div className="ms-top">
                            <h4 className="jp-title"></h4>
                            <div className="jp-time-holder">
                              <div className="jp-current-time" role="timer" aria-label="time">
                                &nbsp;
                              </div>
                              <div className="jp-duration" role="timer" aria-label="duration">
                                &nbsp;
                              </div>
                            </div>
                          </div>
                          <div className="new-play-added">
                            <div className="jp-controls text-center">
                              <button className="jp-play" tabIndex="0">
                                <i className="fas fa-play"></i>
                              </button>
                            </div>
                            <div className="jp-progress">
                              <div className="jp-seek-bar">
                                <div className="jp-play-bar"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="jp-volume-controls"></div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="ganerated-music-list pick-your-tracks">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault8" />
                  <label className="form-check-label" htmlFor="flexRadioDefault8">
                    <div className="ms-player-content">
                      <div className="ms-player jp-state-playing" id="jp_container_2">
                        <div className="ms-thumb">
                          <img src="assets/images/resources/music-list.jpg" alt="" />
                        </div>

                        <div className="title-bar">
                          <div className="ms-top">
                            <h4 className="jp-title"></h4>
                            <div className="jp-time-holder">
                              <div className="jp-current-time" role="timer" aria-label="time">
                                &nbsp;
                              </div>
                              <div className="jp-duration" role="timer" aria-label="duration">
                                &nbsp;
                              </div>
                            </div>
                          </div>
                          <div className="new-play-added">
                            <div className="jp-controls text-center">
                              <button className="jp-play" tabIndex="0">
                                <i className="fas fa-play"></i>
                              </button>
                            </div>
                            <div className="jp-progress">
                              <div className="jp-seek-bar">
                                <div className="jp-play-bar"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="jp-volume-controls"></div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="ganerated-music-list pick-your-tracks">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault9" />
                  <label className="form-check-label" htmlFor="flexRadioDefault9">
                    <div className="ms-player-content">
                      <div className="ms-player jp-state-playing" id="jp_container_2">
                        <div className="ms-thumb">
                          <img src="assets/images/resources/music-list.jpg" alt="" />
                        </div>

                        <div className="title-bar">
                          <div className="ms-top">
                            <h4 className="jp-title"></h4>
                            <div className="jp-time-holder">
                              <div className="jp-current-time" role="timer" aria-label="time">
                                &nbsp;
                              </div>
                              <div className="jp-duration" role="timer" aria-label="duration">
                                &nbsp;
                              </div>
                            </div>
                          </div>
                          <div className="new-play-added">
                            <div className="jp-controls text-center">
                              <button className="jp-play" tabIndex="0">
                                <i className="fas fa-play"></i>
                              </button>
                            </div>
                            <div className="jp-progress">
                              <div className="jp-seek-bar">
                                <div className="jp-play-bar"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="jp-volume-controls"></div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="ganerated-music-list pick-your-tracks">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault10" />
                  <label className="form-check-label" htmlFor="flexRadioDefault10">
                    <div className="ms-player-content">
                      <div className="ms-player jp-state-playing" id="jp_container_2">
                        <div className="ms-thumb">
                          <img src="assets/images/resources/music-list.jpg" alt="" />
                        </div>

                        <div className="title-bar">
                          <div className="ms-top">
                            <h4 className="jp-title"></h4>
                            <div className="jp-time-holder">
                              <div className="jp-current-time" role="timer" aria-label="time">
                                &nbsp;
                              </div>
                              <div className="jp-duration" role="timer" aria-label="duration">
                                &nbsp;
                              </div>
                            </div>
                          </div>
                          <div className="new-play-added">
                            <div className="jp-controls text-center">
                              <button className="jp-play" tabIndex="0">
                                <i className="fas fa-play"></i>
                              </button>
                            </div>
                            <div className="jp-progress">
                              <div className="jp-seek-bar">
                                <div className="jp-play-bar"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="jp-volume-controls"></div>
                      </div>
                    </div>
                  </label>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="mt-4 row justify-content-center">
          <div className="col-xl-3 col-lg-4 col-md-5 col-12 text-center">
            <button
              className="btn-red mobile-space-15 wow slideInUp"
              id="pick-your-tracks-assistant"
              onClick={handelShowNext}
              type="button"
            >
              Customize Your Track
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Tracks