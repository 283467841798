import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
// import Sidebar from "./Sidebar";
import Footer from "./Footer";

const Layout = ({ children }) => {
  return (
    <div className="wrapper">
      <Navbar />
      {children}
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
