import { useState } from "react";

const GenreList = ({ handelShowNext }) => {
  const [genre, setGenre] = useState("Eletronic Dance");
  const handleNext = () => {
    if (genre) handelShowNext();
  };

  return (
    <section className="block black-bg choose-your-genre">
      <div className="container-fluid">
        <div className="section-title-between choose-your-genre-title">
          <div className="title-sec wow fadeInUp">
            <h2>Start your track. Pick your style.</h2>
          </div>
        </div>
        <form>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="your-genre-list form-check">
                <input
                  className="form-check-input"
                  onChange={(e) => setGenre(e.target.value)}
                  checked={genre === "Eletronic Dance"}
                  type="radio"
                  name="flexRadioDefault"
                  value="Eletronic Dance"
                  id="flexCheckDefault1"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault1">
                  <img
                    src="assets/images/resources/icons-check.svg"
                    className="check-icon position-absolute"
                    width="24"
                    alt=""
                  />
                  <div className="playlist-col wow fadeInLeft">
                    <div className="playlist-thumb">
                      <img src="assets/images/resources/choose-1.png" alt="" />
                    </div>
                    <div className="playlist-info">
                      <h3>Eletronic Dance Music</h3>
                      <p>Light and happy with syncopated drums, airy pads, and strong emotions.</p>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="your-genre-list form-check">
                <input
                  className="form-check-input"
                  onChange={(e) => setGenre(e.target.value)}
                  checked={genre === "LO-FI"}
                  type="radio"
                  name="flexRadioDefault"
                  value="LO-FI"
                  id="flexCheckDefault2"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault2">
                  <img
                    src="assets/images/resources/icons-check.svg"
                    className="check-icon position-absolute"
                    width="24"
                    alt=""
                  />
                  <div className="playlist-col wow fadeInLeft" data-wow-delay="200ms">
                    <div className="playlist-thumb">
                      <img src="assets/images/resources/choose-2.png" alt="" />
                    </div>
                    <div className="playlist-info">
                      <h3>LO-FI</h3>
                      <p>Grainy beats to melt into and change your focus.</p>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="your-genre-list form-check">
                <input
                  className="form-check-input"
                  onChange={(e) => setGenre(e.target.value)}
                  checked={genre === "Rap Beats"}
                  type="radio"
                  name="flexRadioDefault"
                  value="Rap Beats"
                  id="flexCheckDefault3"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault3">
                  <img
                    src="assets/images/resources/icons-check.svg"
                    className="check-icon position-absolute"
                    width="24"
                    alt=""
                  />
                  <div className="playlist-col wow fadeInLeft" data-wow-delay="400ms">
                    <div className="playlist-thumb">
                      <img src="assets/images/resources/choose-3.png" alt="" />
                    </div>
                    <div className="playlist-info">
                      <h3>Rap Beats</h3>
                      <p>
                        Create your beat with drums, samples, loops, claps, bass, and heavy kicks to background your
                        rhymes.
                      </p>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="your-genre-list form-check">
                <input
                  className="form-check-input"
                  onChange={(e) => setGenre(e.target.value)}
                  checked={genre === "Trap"}
                  type="radio"
                  name="flexRadioDefault"
                  value="Trap"
                  id="flexCheckDefault4"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault4">
                  <img
                    src="assets/images/resources/icons-check.svg"
                    className="check-icon position-absolute"
                    width="24"
                    alt=""
                  />
                  <div className="playlist-col wow fadeInLeft">
                    <div className="playlist-thumb">
                      <img src="assets/images/resources/choose-4.png" alt="" />
                    </div>
                    <div className="playlist-info">
                      <h3>Trap</h3>
                      <p>Gritty, heavy sub-bass, super rhythmic snares, and a deep 800 kick.</p>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="your-genre-list form-check">
                <input
                  className="form-check-input"
                  onChange={(e) => setGenre(e.target.value)}
                  checked={genre === "Hip-Hop"}
                  type="radio"
                  name="flexRadioDefault"
                  value="Hip-Hop"
                  id="flexCheckDefault5"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault5">
                  <img
                    src="assets/images/resources/icons-check.svg"
                    className="check-icon position-absolute"
                    width="24"
                    alt=""
                  />
                  <div className="playlist-col wow fadeInRight" data-wow-delay="200ms">
                    <div className="playlist-thumb">
                      <img src="assets/images/resources/choose-5.png" alt="" />
                    </div>
                    <div className="playlist-info">
                      <h3>Hip-Hop</h3>
                      <p>Head-bobbing beat with a deep bassline perfect for your verses.</p>
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="your-genre-list form-check">
                <input
                  className="form-check-input"
                  onChange={(e) => setGenre(e.target.value)}
                  checked={genre === "Deep House"}
                  type="radio"
                  name="flexRadioDefault"
                  value="Deep House"
                  id="flexCheckDefault6"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault6">
                  <img
                    src="assets/images/resources/icons-check.svg"
                    className="check-icon position-absolute"
                    width="24"
                    alt=""
                  />
                  <div className="playlist-col wow fadeInRight" data-wow-delay="400ms">
                    <div className="playlist-thumb">
                      <img src="assets/images/resources/choose-6.png" alt="" />
                    </div>
                    <div className="playlist-info">
                      <h3>Deep House</h3>
                      <p>Mix up some Jazz, Funk, and a bit of soul and hit the clubs.</p>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className=" mt-3 row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-5 col-12 text-center">
              <button
                className="btn-red mobile-space-15 wow slideInUp"
                onClick={handleNext}
                // id="select-genra-assistant"
                type="button"
              >
                Customize Your Track
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default GenreList;
