import { useMemo, useState } from "react";
import { INSTRUMENT_LIST, TRACK_LIST } from "../../app.config";
import AudioPlayer from "../AudioPlayer";

const InstrumentSound = ({ handelShowNext, instrumentRef }) => {
  const [search, setSearch] = useState("");
  const [fields, setFields] = useState({
    instruments: [],
  });
  const [playingAudioId, setPlayingAudioId] = useState("");
  const [show, setShow] = useState(false);

  const showTrackList = useMemo(() => {
    return TRACK_LIST.map((track) => {
      return (
        <AudioPlayer
          key={track.id}
          track={track}
          playingAudioId={playingAudioId}
          handlePlayigAudioId={(id) => setPlayingAudioId(id)}
          showOptions={false}
        />
      );
    });
  }, [TRACK_LIST, playingAudioId]);

  const handleInstrument = (e) => {
    const { checked, value } = e.target;
    let tempInstruments = [...fields.instruments];
    if (checked) tempInstruments.push(value);
    else tempInstruments = tempInstruments.filter((item) => item !== value);
    setFields({ ...fields, instruments: tempInstruments });
  };

  const showInstrumentList = INSTRUMENT_LIST.map((instrument, i) => {
    if (instrument.includes(search))
      return (
        <div key={i} className="form-check">
          <div className="d-flex align-items-center">
            <input
              onChange={handleInstrument}
              checked={fields.instruments.some((item) => item === instrument)}
              className="form-check-input"
              type="checkbox"
              value={instrument}
              id="flexCheckDefault1"
            />
            <label className="form-check-label text-capitalize" htmlFor="flexCheckDefault1">
              {instrument}
            </label>
          </div>
        </div>
      );
  });

  return (
    <>
      <div className="row justify-content-center pt-3">
        <div className="col-lg-8 col-md-10 col-12">
          <div className="title-sec mb-3 text-center wow fadeInUp">
            <h2>Your Personal AI Music Assistant</h2>
            <p>
              The Rolling Out AI Music Lab will quickly create a unique track for you. We have millions of samples; all
              you need to do is tell us the key, instruments, genre, beat, mood or vibe you want to achieve with your
              music. Our AI will get to work, and in a second, you’ll have an AI-generated beat or full track.
            </p>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-3 col-lg-4 col-md-12 col-12">
          <div className="ai-music-genrator-main wow fadeInLeft">
            <form className="ai-music-genrator-form instruments-list">
              <div className="basic-iput-data">
                <div className="accordion" id="accordionPanelsStayOpenExample">
                  <div className="accordion-item mt-3">
                    <h2 className="accordion-header select-instruments-title" id="panelsStayOpen-headingTwo">
                      <button
                        className={`accordion-button ${fields.instruments.length > 0 ? "active-title" : ""}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        Instruments
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      className="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingTwo"
                    >
                      <div className="accordion-body">
                        <input
                          onChange={(e) => setSearch(e.target.value)}
                          type="text"
                          className="form-control w-100 mb-3 search-input"
                          placeholder="Search instrument..."
                        />
                        <div className="ai-music-genrator-form instruments-list select-instruments">
                          {search ? (
                            showInstrumentList
                          ) : (
                            <>
                              <div className="form-check">
                                <div className="d-flex align-items-center">
                                  <input
                                    onChange={handleInstrument}
                                    checked={fields.instruments.some((item) => item === "piano")}
                                    className="form-check-input"
                                    type="checkbox"
                                    value="piano"
                                    id="flexCheckDefault1"
                                  />
                                  <label className="form-check-label" htmlFor="flexCheckDefault1">
                                    Piano
                                  </label>
                                </div>
                              </div>
                              <div className="form-check">
                                <div className="d-flex align-items-center">
                                  <input
                                    onChange={handleInstrument}
                                    checked={fields.instruments.some((item) => item === "keyboard")}
                                    className="form-check-input"
                                    type="checkbox"
                                    value="keyboard"
                                    id="flexCheckChecked2"
                                  />
                                  <label className="form-check-label" htmlFor="flexCheckChecked2">
                                    Keyboard
                                  </label>
                                </div>
                              </div>
                              <div className="form-check">
                                <div className="d-flex align-items-center">
                                  <input
                                    onChange={handleInstrument}
                                    checked={fields.instruments.some((item) => item === "acoustic guitar")}
                                    className="form-check-input"
                                    type="checkbox"
                                    value="acoustic guitar"
                                    id="flexCheckDefault3"
                                  />
                                  <label className="form-check-label" htmlFor="flexCheckDefault3">
                                    Acoustic Guitar
                                  </label>
                                </div>
                              </div>
                              <div className="form-check">
                                <div className="d-flex align-items-center">
                                  <input
                                    onChange={handleInstrument}
                                    checked={fields.instruments.some((item) => item === "electric guitar")}
                                    className="form-check-input"
                                    type="checkbox"
                                    value="electric guitar"
                                    id="flexCheckChecked4"
                                  />
                                  <label className="form-check-label" htmlFor="flexCheckChecked4">
                                    Electric Guitar
                                  </label>
                                </div>
                              </div>
                              <div className="form-check">
                                <div className="d-flex align-items-center">
                                  <input
                                    onChange={handleInstrument}
                                    checked={fields.instruments.some((item) => item === "bell")}
                                    className="form-check-input"
                                    type="checkbox"
                                    value="bell"
                                    id="flexCheckDefault5"
                                  />
                                  <label className="form-check-label" htmlFor="flexCheckDefault5">
                                    Bell
                                  </label>
                                </div>
                              </div>
                              <div className="form-check">
                                <div className="d-flex align-items-center">
                                  <input
                                    onChange={handleInstrument}
                                    checked={fields.instruments.some((item) => item === "mallet")}
                                    className="form-check-input"
                                    type="checkbox"
                                    value="mallet"
                                    id="flexCheckChecked6"
                                  />
                                  <label className="form-check-label" htmlFor="flexCheckChecked6">
                                    Mallet
                                  </label>
                                </div>
                              </div>
                              <div className="form-check">
                                <div className="d-flex align-items-center">
                                  <input
                                    onChange={handleInstrument}
                                    checked={fields.instruments.some((item) => item === "drums")}
                                    className="form-check-input"
                                    type="checkbox"
                                    value="drums"
                                    id="flexCheckDefault7"
                                  />
                                  <label className="form-check-label" htmlFor="flexCheckDefault7">
                                    Drums
                                  </label>
                                </div>
                              </div>
                              <div className="form-check">
                                <div className="d-flex align-items-center">
                                  <input
                                    onChange={handleInstrument}
                                    checked={fields.instruments.some((item) => item === "strings")}
                                    className="form-check-input"
                                    type="checkbox"
                                    value="strings"
                                    id="flexCheckChecked8"
                                  />
                                  <label className="form-check-label" htmlFor="flexCheckChecked8">
                                    Strings
                                  </label>
                                </div>
                              </div>
                              <div className="form-check">
                                <div className="d-flex align-items-center">
                                  <input
                                    onChange={handleInstrument}
                                    checked={fields.instruments.some((item) => item === "voice")}
                                    className="form-check-input"
                                    type="checkbox"
                                    value="voice"
                                    id="flexCheckDefault9"
                                  />
                                  <label className="form-check-label" htmlFor="flexCheckDefault9">
                                    Voice
                                  </label>
                                </div>
                              </div>
                              <div className="form-check">
                                <div className="d-flex align-items-center">
                                  <input
                                    onChange={handleInstrument}
                                    checked={fields.instruments.some((item) => item === "brass")}
                                    className="form-check-input"
                                    type="checkbox"
                                    value="brass"
                                    id="flexCheckChecked10"
                                  />
                                  <label className="form-check-label" htmlFor="flexCheckChecked10">
                                    Brass
                                  </label>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="btn-red mt-4 w-100  wow slideInUp"
                  id="btn-ganerate-music-assistant"
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(true);
                  }}
                >
                  Generate
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="col-xl-9 col-lg-8 col-md-12 col-12">
          <div className="row mt-4">
            <div className="col-md-12 col-12">
              {!show && (
                <div className="ganerated-music-null">
                  <img src="assets/images/resources/music-headphone.png" className="img-fluid" />
                </div>
              )}

              {show && (
                <div
                  className="ganerated-music-body ganerator-song-assistant-sub mt-3"
                  id="select-instruments-song-sound"
                >
                  <div className="playlist-info mb-3">
                    <h3>Select Your instrument sounds</h3>
                    <p>
                      Customize your epic flow by adding beats, and instruments or you can speed it up. Then your
                      personal Rolling Out AI Music Assistant will finish your new track and it will be ready for your
                      playlist.
                    </p>
                  </div>
                  <div className="pick-your-tracks-body">
                    {showTrackList}
                    {/* <div className="ganerated-music-list pick-your-tracks">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                      <label className="form-check-label" htmlFor="flexRadioDefault1">
                        <div className="ms-player-content">
                          <div className="ms-player jp-state-playing" id="jp_container_2">
                            <div className="ms-thumb">
                              <img src="assets/images/resources/music-list.jpg" alt="" />
                            </div>

                            <div className="title-bar">
                              <div className="ms-top">
                                <h4 className="jp-title"></h4>
                                <div className="jp-time-holder">
                                  <div className="jp-current-time" role="timer" aria-label="time">
                                    &nbsp;
                                  </div>
                                  <div className="jp-duration" role="timer" aria-label="duration">
                                    &nbsp;
                                  </div>
                                </div>
                              </div>
                              <div className="new-play-added">
                                <div className="jp-controls text-center">
                                  <button className="jp-play" tabIndex="0">
                                    <i className="fas fa-play"></i>
                                  </button>
                                </div>
                                <div className="jp-progress">
                                  <div className="jp-seek-bar">
                                    <div className="jp-play-bar"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="jp-volume-controls"></div>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="ganerated-music-list pick-your-tracks">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                      <label className="form-check-label" htmlFor="flexRadioDefault2">
                        <div className="ms-player-content">
                          <div className="ms-player jp-state-playing" id="jp_container_2">
                            <div className="ms-thumb">
                              <img src="assets/images/resources/music-list.jpg" alt="" />
                            </div>

                            <div className="title-bar">
                              <div className="ms-top">
                                <h4 className="jp-title"></h4>
                                <div className="jp-time-holder">
                                  <div className="jp-current-time" role="timer" aria-label="time">
                                    &nbsp;
                                  </div>
                                  <div className="jp-duration" role="timer" aria-label="duration">
                                    &nbsp;
                                  </div>
                                </div>
                              </div>
                              <div className="new-play-added">
                                <div className="jp-controls text-center">
                                  <button className="jp-play" tabIndex="0">
                                    <i className="fas fa-play"></i>
                                  </button>
                                </div>
                                <div className="jp-progress">
                                  <div className="jp-seek-bar">
                                    <div className="jp-play-bar"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="jp-volume-controls"></div>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="ganerated-music-list pick-your-tracks">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                      <label className="form-check-label" htmlFor="flexRadioDefault3">
                        <div className="ms-player-content">
                          <div className="ms-player jp-state-playing" id="jp_container_2">
                            <div className="ms-thumb">
                              <img src="assets/images/resources/music-list.jpg" alt="" />
                            </div>

                            <div className="title-bar">
                              <div className="ms-top">
                                <h4 className="jp-title"></h4>
                                <div className="jp-time-holder">
                                  <div className="jp-current-time" role="timer" aria-label="time">
                                    &nbsp;
                                  </div>
                                  <div className="jp-duration" role="timer" aria-label="duration">
                                    &nbsp;
                                  </div>
                                </div>
                              </div>
                              <div className="new-play-added">
                                <div className="jp-controls text-center">
                                  <button className="jp-play" tabIndex="0">
                                    <i className="fas fa-play"></i>
                                  </button>
                                </div>
                                <div className="jp-progress">
                                  <div className="jp-seek-bar">
                                    <div className="jp-play-bar"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="jp-volume-controls"></div>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="ganerated-music-list pick-your-tracks">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                      <label className="form-check-label" htmlFor="flexRadioDefault4">
                        <div className="ms-player-content">
                          <div className="ms-player jp-state-playing" id="jp_container_2">
                            <div className="ms-thumb">
                              <img src="assets/images/resources/music-list.jpg" alt="" />
                            </div>

                            <div className="title-bar">
                              <div className="ms-top">
                                <h4 className="jp-title"></h4>
                                <div className="jp-time-holder">
                                  <div className="jp-current-time" role="timer" aria-label="time">
                                    &nbsp;
                                  </div>
                                  <div className="jp-duration" role="timer" aria-label="duration">
                                    &nbsp;
                                  </div>
                                </div>
                              </div>
                              <div className="new-play-added">
                                <div className="jp-controls text-center">
                                  <button className="jp-play" tabIndex="0">
                                    <i className="fas fa-play"></i>
                                  </button>
                                </div>
                                <div className="jp-progress">
                                  <div className="jp-seek-bar">
                                    <div className="jp-play-bar"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="jp-volume-controls"></div>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="ganerated-music-list pick-your-tracks">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" />
                      <label className="form-check-label" htmlFor="flexRadioDefault5">
                        <div className="ms-player-content">
                          <div className="ms-player jp-state-playing" id="jp_container_2">
                            <div className="ms-thumb">
                              <img src="assets/images/resources/music-list.jpg" alt="" />
                            </div>

                            <div className="title-bar">
                              <div className="ms-top">
                                <h4 className="jp-title"></h4>
                                <div className="jp-time-holder">
                                  <div className="jp-current-time" role="timer" aria-label="time">
                                    &nbsp;
                                  </div>
                                  <div className="jp-duration" role="timer" aria-label="duration">
                                    &nbsp;
                                  </div>
                                </div>
                              </div>
                              <div className="new-play-added">
                                <div className="jp-controls text-center">
                                  <button className="jp-play" tabIndex="0">
                                    <i className="fas fa-play"></i>
                                  </button>
                                </div>
                                <div className="jp-progress">
                                  <div className="jp-seek-bar">
                                    <div className="jp-play-bar"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="jp-volume-controls"></div>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="ganerated-music-list pick-your-tracks">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault6" />
                      <label className="form-check-label" htmlFor="flexRadioDefault6">
                        <div className="ms-player-content">
                          <div className="ms-player jp-state-playing" id="jp_container_2">
                            <div className="ms-thumb">
                              <img src="assets/images/resources/music-list.jpg" alt="" />
                            </div>

                            <div className="title-bar">
                              <div className="ms-top">
                                <h4 className="jp-title"></h4>
                                <div className="jp-time-holder">
                                  <div className="jp-current-time" role="timer" aria-label="time">
                                    &nbsp;
                                  </div>
                                  <div className="jp-duration" role="timer" aria-label="duration">
                                    &nbsp;
                                  </div>
                                </div>
                              </div>
                              <div className="new-play-added">
                                <div className="jp-controls text-center">
                                  <button className="jp-play" tabIndex="0">
                                    <i className="fas fa-play"></i>
                                  </button>
                                </div>
                                <div className="jp-progress">
                                  <div className="jp-seek-bar">
                                    <div className="jp-play-bar"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="jp-volume-controls"></div>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="ganerated-music-list pick-your-tracks">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault7" />
                      <label className="form-check-label" htmlFor="flexRadioDefault7">
                        <div className="ms-player-content">
                          <div className="ms-player jp-state-playing" id="jp_container_2">
                            <div className="ms-thumb">
                              <img src="assets/images/resources/music-list.jpg" alt="" />
                            </div>

                            <div className="title-bar">
                              <div className="ms-top">
                                <h4 className="jp-title"></h4>
                                <div className="jp-time-holder">
                                  <div className="jp-current-time" role="timer" aria-label="time">
                                    &nbsp;
                                  </div>
                                  <div className="jp-duration" role="timer" aria-label="duration">
                                    &nbsp;
                                  </div>
                                </div>
                              </div>
                              <div className="new-play-added">
                                <div className="jp-controls text-center">
                                  <button className="jp-play" tabIndex="0">
                                    <i className="fas fa-play"></i>
                                  </button>
                                </div>
                                <div className="jp-progress">
                                  <div className="jp-seek-bar">
                                    <div className="jp-play-bar"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="jp-volume-controls"></div>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="ganerated-music-list pick-your-tracks">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault8" />
                      <label className="form-check-label" htmlFor="flexRadioDefault8">
                        <div className="ms-player-content">
                          <div className="ms-player jp-state-playing" id="jp_container_2">
                            <div className="ms-thumb">
                              <img src="assets/images/resources/music-list.jpg" alt="" />
                            </div>

                            <div className="title-bar">
                              <div className="ms-top">
                                <h4 className="jp-title"></h4>
                                <div className="jp-time-holder">
                                  <div className="jp-current-time" role="timer" aria-label="time">
                                    &nbsp;
                                  </div>
                                  <div className="jp-duration" role="timer" aria-label="duration">
                                    &nbsp;
                                  </div>
                                </div>
                              </div>
                              <div className="new-play-added">
                                <div className="jp-controls text-center">
                                  <button className="jp-play" tabIndex="0">
                                    <i className="fas fa-play"></i>
                                  </button>
                                </div>
                                <div className="jp-progress">
                                  <div className="jp-seek-bar">
                                    <div className="jp-play-bar"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="jp-volume-controls"></div>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="ganerated-music-list pick-your-tracks">
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault9" />
                      <label className="form-check-label" htmlFor="flexRadioDefault9">
                        <div className="ms-player-content">
                          <div className="ms-player jp-state-playing" id="jp_container_2">
                            <div className="ms-thumb">
                              <img src="assets/images/resources/music-list.jpg" alt="" />
                            </div>

                            <div className="title-bar">
                              <div className="ms-top">
                                <h4 className="jp-title"></h4>
                                <div className="jp-time-holder">
                                  <div className="jp-current-time" role="timer" aria-label="time">
                                    &nbsp;
                                  </div>
                                  <div className="jp-duration" role="timer" aria-label="duration">
                                    &nbsp;
                                  </div>
                                </div>
                              </div>
                              <div className="new-play-added">
                                <div className="jp-controls text-center">
                                  <button className="jp-play" tabIndex="0">
                                    <i className="fas fa-play"></i>
                                  </button>
                                </div>
                                <div className="jp-progress">
                                  <div className="jp-seek-bar">
                                    <div className="jp-play-bar"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="jp-volume-controls"></div>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="ganerated-music-list pick-your-tracks">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault10"
                      />
                      <label className="form-check-label" htmlFor="flexRadioDefault10">
                        <div className="ms-player-content">
                          <div className="ms-player jp-state-playing" id="jp_container_2">
                            <div className="ms-thumb">
                              <img src="assets/images/resources/music-list.jpg" alt="" />
                            </div>

                            <div className="title-bar">
                              <div className="ms-top">
                                <h4 className="jp-title"></h4>
                                <div className="jp-time-holder">
                                  <div className="jp-current-time" role="timer" aria-label="time">
                                    &nbsp;
                                  </div>
                                  <div className="jp-duration" role="timer" aria-label="duration">
                                    &nbsp;
                                  </div>
                                </div>
                              </div>
                              <div className="new-play-added">
                                <div className="jp-controls text-center">
                                  <button className="jp-play" tabIndex="0">
                                    <i className="fas fa-play"></i>
                                  </button>
                                </div>
                                <div className="jp-progress">
                                  <div className="jp-seek-bar">
                                    <div className="jp-play-bar"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="jp-volume-controls"></div>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div> */}
                  </div>
                  {/* <div className="text-center">
                  <button className="btn-red mt-4 wow slideInUp" id="btn-ganerate-music">
                    Generate
                  </button>
                </div> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {show && (
        <div className="mt-4 row justify-content-center ganerator-song-assistant-sub-btn">
          <div className="col-xl-3 col-lg-4 col-md-5 col-12 text-center">
            <button
              onClick={handelShowNext}
              className="btn-red mobile-space-15 w-100 wow slideInUp"
              id="btn-ganerate-music"
              type="button"
            >
              Generate
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default InstrumentSound;
